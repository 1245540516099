var idioma = $('html').attr('lang');
$('.houdiniForm').each(function() {//per cada formulari
    'use strict';
    //validacio formulari
    $(this).validate({
//        errorPlacement: function(error, element) {
//            if (element.attr('type') === 'checkbox' || element.attr('type') === 'radio') {
//                if (element.closest('div').find('input').length > 1) {
//                    error.insertAfter(element.closest('div').find('p.type-label'));
//                } else {
//                    error.insertAfter(element.parent('label'));
//                }
//            } else {
//                error.insertAfter(element);
//            }
//        }
    	errorElement: "div",
		errorPlacement: function ( error, element ) {
			// Add the `help-block` class to the error element
			error.addClass( "form-control-feedback" );

			if ( element.prop( "type" ) === "checkbox" ) {
				error.insertAfter( element.parent( "label" ) );
			} else {
				error.insertAfter( element );
			}
		},
		success: function ( label, element ) {
		},
		highlight: function ( element, errorClass, validClass ) {
			$( element ).parents( ".form-group" ).addClass( "has-danger" ).removeClass( "has-success" );
			$( element ).addClass( "form-control-danger" );
		},
		unhighlight: function ( element, errorClass, validClass ) {
			$( element ).parents( ".form-group" ).removeClass( "has-danger" );
			$( element ).removeClass( "form-control-danger" );
		}
    });
});

//datapicker
var datepickerConf = {
    	format: "dd/mm/yyyy",
        todayBtn: "linked",
        clearBtn: true,
        language: "en",
        calendarWeeks: true,
        autoclose: true,
        todayHighlight: true
    };
if ($(".datepicker").length > 0 ) {
    if ($(".datepicker").length > 0) {
        $(".datepicker").datepicker(datepickerConf);
    }
}

//chosen
$('.houdiniForm .chzn-select').select2();
$('.houdiniForm .chzn-select-deselect').select2({
		width: "100%",
		allowClear: true
	}
);
$('.multiple-select-help').hide(); //amaga misatge d'ajuda dels selects multiples


//$.fn.raty.defaults.path = '/media/img';
//$.fn.raty.defaults.cancel = false;

//valoracio
$('.houdiniForm .scoreField').each(function() {
    'use strict';
    var field = $(this);
    var id = '#' + field.attr('id');
    var score = $('<div></div>');
    score.raty({
        half: true,
        hints: ['', '', '', '', ''],
        score: function() {
            return field.val();
        },
        targetScore: id
    });
    field.hide();
    field.after(score);
});

//select de dnis
$('.houdiniForm .dninifnie').each(function() {
    'use strict';
    $(this).find('select').on('change', function() {
        var value = $(this).val();
        var input = $(this).closest('div.row').find('input');

        if (value === 'DNI') {
            input.rules('add', {
                dni: true,
                cifES: false,
                nieES: false
            });
        } else if (value === 'CIF') {
            input.rules('add', {
                dni: false,
                cifES: true,
                nieES: false
            });
        } else if (value === 'NIE') {
            input.rules('add', {
                dni: false,
                cifES: false,
                nieES: true
            });
        }
    });
});



$('.multiFields').each(function() {
    var multi = $(this);
    if (multi.data('disabled')) {
        multi.find('.move, .add, .delete').hide();
        return;
    }

    var newField = multi.find('ul').data('new');
    var next = multi.find('ul').data('next');
    var max = multi.find('ul').data('max');

    multi.find('.imageContainer div.deleteImage').each(function() {
        $(this).append('<a href="#" class="btn btn-danger deleteBtn"><i class="fa fa-trash"></i></a>');
        $(this).find('label').hide();
        $(this).find('a.deleteBtn').on('click', function() {
            $(this).closest('.imageContainer').find('.popupImage').remove();
            $(this).parent().find('input').attr('checked', 'checked');
            $(this).remove();
            return false;
        });
    });

//    multi.find('ul').sortable({
//        handle: ".move",
//        start: function(event, ui) {
//            ui.item.find("textarea[class*='editor']").each(function() {
//                var ckeditor = CKEDITOR.instances[$(this).attr('name')];
//                ckeditor.destroy();
//            });
//        },
//        stop: function(event, ui) {
//            $(this).find('input.order').each(function(idx) {
//                $(this).val(idx);
//            });
//
//            ui.item.find("textarea[class*='editor']").each(function() {
//                var editorClassName = $(this).attr('class').match(/editor\w+/);
//                if (editorClassName) {
//                    addEditor($(this), editorClassName[0].substring(6));
//                }
//            });
//        }
//    });
    multi.on('hidden.bs.collapse', '.collapse', function (){
        //$(this).closest('.multiContent').find('.toclose').hide()
        $(this).closest('.multiContent').find('.toopen').show()
    }).on('hide.bs.collapse', '.collapse', function (){
        $(this).closest('.multiContent').find('.toclose').hide()
        $(this).closest('.multiContent').find('.toopen').hide()
    }).on('shown.bs.collapse', '.collapse', function(){
        //$(this).closest('.multiContent').find('.toopen').hide()
        $(this).closest('.multiContent').find('.toclose').show()
    }).on('show.bs.collapse', '.collapse', function(){
        $(this).closest('.multiContent').find('.toopen').hide()
        $(this).closest('.multiContent').find('.toclose').hide()
    });

    if (max > 0 && multi.find('li').length >= max) {
        multi.find('a.add').hide();
    } else {
        multi.find('a.add').show();
    }


    multi.find('ul').on('click', 'li a.delete', function(event) {
        event.preventDefault();
        if (max > 0 && $(this).closest('ul').find('li').length > max) {
            $(this).closest('div.multiFields').find('a.add').hide();
        } else {
            $(this).closest('div.multiFields').find('a.add').show();
        }
        $(this).closest('li').remove();
    });

    multi.find('a.add').bind('click', function(event) {
        event.preventDefault();

        var ul = $(this).closest('div').find('ul');

        if (max > 0 && ul.find('li').length >= max) {
            return false;
        }

        ul.append(newField.replace(/\[id\]/g, '[' + next + ']').replace(/_collapse_id/g, '_collapse_' + next));

//        ul.find('li:last-child').find("textarea[class*='editor']").each(function() {
//            var editorClassName = $(this).attr('class').match(/editor\w+/);
//            if (editorClassName) {
//                addEditor($(this), editorClassName[0].substring(6));
//            }
//        });

        ul.find('li:last-child').find("input.datepicker").each(function() {
        	$(this).datepicker(datepickerConf);
	    });

        ul.find('input.order').each(function(idx) {
            $(this).val(idx);
        });

        ul.find('*[rel=tooltip]').tooltip({
            animation: false
        });
        next++;

        if (max > 0 && ul.find('li').length >= max) {
            $(this).hide();
        } else {
            $(this).show();
        }


        return false;
    });
});