// filters
$.expr[':'].external = function(obj) {
    'use strict';
    try {
        if (obj.href) {
            return !obj.href.match(/^mailto\:/) && (obj.hostname != location.hostname);
        }
        return false;
    } catch (e) {
        return false;
    }
};
$.expr[':'].download = function(obj) {
    'use strict';
    try {
        if (obj.href) {
            return (obj.href.match(/\.(pdf|doc|docx|xls||xlsx|cvx|jpg|gif|mp3|swf|txt|ppt|zip|gz|dmg)$/) !==  null);
        }
        return false;
    } catch (e) {
        return false;
    }
};
$.expr[':'].image = function(obj) {
    'use strict';
    try {
        if (obj.href) {
            return (obj.href.match(/\.(jpg|png|jpeg|JPG|PNG)$/) !==  null);
        }
        return false;
    } catch (e) {
        return false;
    }
};

$(document).ready(function() {
    'use strict';
    // enllaços externs en finestra nova
    $('a:external, a[rel=external]').attr('target', '_blank').addClass('external').attr('rel', 'external');

});


//autosize($('textarea.autosize'));

$("a.confirmDelete").click(function(event) {
    event.preventDefault();
    var url=$(this).attr('href');
    vex.dialog.confirm({
        message: 'Are you sure you want to delete this item?',
        buttons: [
            $.extend({}, vex.dialog.buttons.YES, {
                text: 'Delete'
            }), $.extend({}, vex.dialog.buttons.NO, {
                text: 'Cancel'
            })
        ],
        callback: function(value) {
            if (value) {
                window.location = url
            }
        }
    });
});

/*$("a.sendVCard").click(function(event) {
    event.preventDefault();
    var url=$(this).attr('href');
    vex.dialog.open({
        message: 'Enviament de la targeta de visita',
        input: [
        	'<div class="vex-container">',
        	'<div class="vex-custom-field-wrapper">',
        		'<label for="date">Correu electrònic</label>',
        		'<div class="vex-custom-input-wrapper">',
                    '<input name="email" type="email" value="" />',
                '</div>',
            '</div>',
            '<div class="vex-custom-field-wrapper">',
	    		'<label for="date">Missatge</label>',
	    		'<div class="vex-custom-input-wrapper">',
	                '<textarea name="body" placeholder="Escriu el missatge que rebrà la persona a la qual envies la targeta de visita"/></textarea>',
	            '</div>',
	        '</div>',
	        '</div>',
        ].join(''),
        buttons: [
            $.extend({}, vex.dialog.buttons.YES, {
                text: 'Enviar'
            }), $.extend({}, vex.dialog.buttons.NO, {
                text: 'Cancel·lar'
            })
        ],
        onSubmit: function(e) {
        	e.preventDefault();

        	var ajaxUrl = url;
            var ajaxData = $(e.target).serialize();
            $.ajax({
                url: ajaxUrl,
                data: ajaxData,
                type: 'get',
                dataType: 'json',
                success: function(response){
                    if(response.type == 'error'){
                        $(".vex-container").prepend('<div class="alert alert-danger" role="alert">' + response.message + '</div>');
                    } else if (response.type == 'success'){
                    	$(".vex-container").html('<div class="alert alert-success" role="alert">' + response.message + '</div>');
                    	$(".vex-dialog-buttons").hide();


                    	setTimeout(function(){vex.closeAll();}, 3000);
                    }
                },
                error: function(response) {
                	console.log(response);
                }
            });
        	return false;
        }
    });
});*/


$(document).ready(function() {
//	vex.defaultOptions.className = "vex-theme-default";
//	vex.defaultOptions.className = "vex-theme-flat-attack";
	vex.defaultOptions.className = "vex-theme-plain";
	$('body').bootstrapMaterialDesign();


	/*
	 * Calendar mensual
	 */
	if ($('#month-calendar').length) {
	    $('#month-calendar').fullCalendar({
	      header: {
	        left: '',//'prev,next today',
	        center: 'title',
	        right: '' //'month,basicWeek,basicDay'
	      },
	      themeSystem: 'bootstrap4',
	      defaultDate: calendarDefaultDate,
	      navLinks: false, // can click day/week names to navigate views
//	      editable: true,
	      eventLimit: true, // allow "more" link when too many events
	      events: calendarEvents
//	      events: function(start, end, timezone, callback) {
//    	    $.ajax({
//    	      url: '/calendari/ajax-events-load.php',
//    	      dataType: 'json',
//    	      data: {
//    	        // our hypothetical feed requires UNIX timestamps
//    	        start: start.unix(),
//    	        end: end.unix()
//    	      },
//    	      success: function(doc) {
//    	        var events = doc;
//    	        callback(events);
//    	      }
//    	    });
//    	  },
//	      events: [
//	        {
//	          title: 'All Day Event',
//	          start: '2018-03-01'
//	        },
//	        {
//	          title: 'Long Event',
//	          start: '2018-03-07',
//	          end: '2018-03-10'
//	        },
//	        {
//	          id: 999,
//	          title: 'Repeating Event',
//	          start: '2018-03-09T16:00:00'
//	        },
//	        {
//	          id: 999,
//	          title: 'Repeating Event',
//	          start: '2018-03-16T16:00:00'
//	        },
//	        {
//	          title: 'Conference',
//	          start: '2018-03-11',
//	          end: '2018-03-13'
//	        },
//	        {
//	          title: 'Meeting',
//	          start: '2018-03-12T10:30:00',
//	          end: '2018-03-12T12:30:00'
//	        },
//	        {
//	          title: 'Lunch',
//	          start: '2018-03-12T12:00:00'
//	        },
//	        {
//	          title: 'Meeting',
//	          start: '2018-03-12T14:30:00'
//	        },
//	        {
//	          title: 'Happy Hour',
//	          start: '2018-03-12T17:30:00'
//	        },
//	        {
//	          title: 'Dinner',
//	          start: '2018-03-12T20:00:00'
//	        },
//	        {
//	          title: 'Birthday Party',
//	          start: '2018-03-13T07:00:00'
//	        },
//	        {
//	          title: 'Click for Google',
//	          url: 'http://google.com/',
//	          start: '2018-03-28'
//	        }
//	      ]
	    });
	}

	/*
	 * Calendar anual
	 */
	if ($('#year-calendar').length) {
	    $('#year-calendar').calendar({
	    	maxDate: anualCalendarMaxDate,
	    	minDate: anualCalendarMinDate,
	    	startYear: anualCalendarStartYear,
	        enableContextMenu: true,
	        language: 'ca',
	        style: 'background',
//	        enableRangeSelection: true,
//	        contextMenuItems:[
//	            {
//	                text: 'Update',
//	                click: editEvent
//	            },
//	            {
//	                text: 'Delete',
//	                click: deleteEvent
//	            }
//	        ],
//	        selectRange: function(e) {
//	            editEvent({ startDate: e.startDate, endDate: e.endDate });
//	        },
	        mouseOnDay: function(e) {
	            if(e.events.length > 0) {
	                var content = '';

	                for(var i in e.events) {
	                    content += '<div class="event-tooltip-content">'
	                                    + '<div class="event-name" style="color:' + e.events[i].color + '">' + e.events[i].name + '</div>'
	                                    + '<div class="event-location">' + e.events[i].location + '</div>'
	                                + '</div>';
	                }

	                $(e.element).popover({
	                    trigger: 'manual',
	                    container: 'body',
	                    html:true,
	                    content: content
	                });

	                $(e.element).popover('show');
	            }
	        },
	        mouseOutDay: function(e) {
	            if(e.events.length > 0) {
	                $(e.element).popover('hide');
	            }
	        },
	        dayContextMenu: function(e) {
	            $(e.element).popover('hide');
	        },
	        dataSource: anualCalendarEvents
//	        dataSource: [
//	            {
//	                id: 0,
//	                name: 'Google I/O',
//	                location: 'San Francisco, CA',
//	                startDate: new Date(currentYear, 4, 28),
//	                endDate: new Date(currentYear, 4, 29)
//	            },
//	            {
//	                id: 1,
//	                name: 'Microsoft Convergence',
//	                location: 'New Orleans, LA',
//	                startDate: new Date(currentYear, 2, 16),
//	                endDate: new Date(currentYear, 2, 19)
//	            },
//	            {
//	                id: 2,
//	                name: 'Microsoft Build Developer Conference',
//	                location: 'San Francisco, CA',
//	                startDate: new Date(currentYear, 3, 29),
//	                endDate: new Date(currentYear, 4, 1)
//	            },
//	            {
//	                id: 3,
//	                name: 'Apple Special Event',
//	                location: 'San Francisco, CA',
//	                startDate: new Date(currentYear, 8, 1),
//	                endDate: new Date(currentYear, 8, 1)
//	            },
//	            {
//	                id: 4,
//	                name: 'Apple Keynote',
//	                location: 'San Francisco, CA',
//	                startDate: new Date(currentYear, 8, 9),
//	                endDate: new Date(currentYear, 8, 9)
//	            },
//	            {
//	                id: 5,
//	                name: 'Chrome Developer Summit',
//	                location: 'Mountain View, CA',
//	                startDate: new Date(currentYear, 10, 17),
//	                endDate: new Date(currentYear, 10, 18)
//	            },
//	            {
//	                id: 6,
//	                name: 'F8 2015',
//	                location: 'San Francisco, CA',
//	                startDate: new Date(currentYear, 2, 25),
//	                endDate: new Date(currentYear, 2, 26)
//	            },
//	            {
//	                id: 7,
//	                name: 'Yahoo Mobile Developer Conference',
//	                location: 'New York',
//	                startDate: new Date(currentYear, 7, 25),
//	                endDate: new Date(currentYear, 7, 26)
//	            },
//	            {
//	                id: 8,
//	                name: 'Android Developer Conference',
//	                location: 'Santa Clara, CA',
//	                startDate: new Date(currentYear, 11, 1),
//	                endDate: new Date(currentYear, 11, 4)
//	            },
//	            {
//	                id: 9,
//	                name: 'LA Tech Summit',
//	                location: 'Los Angeles, CA',
//	                startDate: new Date(currentYear, 10, 17),
//	                endDate: new Date(currentYear, 10, 17)
//	            }
//	        ]
	    });
	}
});



$('table.dataTable').each(function( index ) {
    var paginate = true;
    if ($(this).find('tr').length <= 10) {
        paginate = false;
    }
    var scroll = false;

    if ($(this).data('scroll')) {
        scroll = true;
        //$(this).find('th, tr').css('white-space', 'nowrap');
    }
    $(this).DataTable({
        bPaginate: paginate,
        bInfo: paginate,
        scrollX: scroll,
        autoWidth: false,
        dom:
            "<'row'<'col-sm-8'i><'col-sm-4'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-5'l><'col-sm-7'p>>",
        stateSave: true,
        stateDuration: 60 * 60,
        /*language: {
            "lengthMenu": "Mostrant _MENU_ entrades per pàgina",
            "zeroRecords": "No s'ha trobat cap entrada",
            "info": "Mostrant pàgina _PAGE_ de _PAGES_",
            "infoEmpty": "Cap entrada disponible",
            "infoFiltered": "(de _MAX_ entrades totals)",
            "search": "Cerca:",
            "paginate": {
                "first": "Primera",
                "last": "Última",
                "next": "Següent",
                "previous": "Anterior"
            }
        },*/
        initComplete: function () {
            this.api().columns().every( function (i) {
                var column = this;
                var titolLabel = '';
                if ($(column.header()).data('select-search') == true) {
                	var titolLabel = $(column.header()).data('select-search-label');
                	if(typeof titolLabel == "undefined") {
                		titolLabel = $(column.header()).text();
                	}
                	var info = $(column.header()).data('select-search-items');
                	if(typeof info == "undefined") {
	                    info = column.data().map(function(obj){
	//                        if ($(obj).text()) {
	//                            return $(obj).text();
	//                        } else {
	                            return obj;
	//                        }
	                    });
	                    var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
	                    info = info.unique().sort(collator.compare);
                	}

                    if (info.length > 1) {
                        var search = $(column.header()).closest('.dataTables_wrapper').find('.dataTables_filter');
//                        search.append('<label>' + $(titol).html() + ':</label>');
                        var select = $('<select class="form-control input-sm"><option value="">' + titolLabel + '</option></select>')
                            .appendTo( search );

                        select.on( 'change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );
                                column
                                    .search( val ? '^'+val+'$' : '', true, false )
                                    .draw();
                            } );
                        $.each(info, function ( key, value ) {
                            //TODO: quant no hi ha html falla al map
                            //TODO: no mantÃ© el select al canviar de pÃ gina
                            select.append( '<option value="'+value+'">'+value+'</option>' )
                        } );
                    }



                }
            } );
        }
    });

    $('#autocomplete').bind('keydown', function(e) {
        console.log(hasDownBeenPressed);
          if (e.keyCode === 40) {
            hasDownBeenPressed = true;
          }else if (e.keyCode !== 13) {
            hasDownBeenPressed = false;
          }
          if (e.keyCode === 13) {
              e.preventDefault();
              if (!hasDownBeenPressed) {
                var firstResult = $(".pac-container .pac-item:first").text();

                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({"address":firstResult }, function(results, status) {
                  if (status == google.maps.GeocoderStatus.OK) {
                        var place = results[0], placeName = results[0].formatted_address;
                        //console.log(place);
                        if(typeof place.address_components != "undefined"){
                          document.getElementById('coordenades_lat').value = place.geometry.location.lat();
                          document.getElementById('coordenades_lng').value = place.geometry.location.lng();
                          for (var i = 0; i < place.address_components.length; i++) {
                            var addressType = place.address_components[i].types[0];

                            if (componentForm[addressType]) {
                              var val = place.address_components[i][componentForm[addressType]];
                              //console.log(val);
                              document.getElementById(addressType).value = val;
                            }

                          }
                        }
                        $("#autocomplete").val(placeName);
                  }
                });
              }
          }
      });
});


